import React from "react";
import styled from "styled-components";

const Header = styled.h2`
    margin: 5% 0%;
  `;

const Text = styled.p`
    text-align: left;
    margin-left: 20%;
    max-width: 60%
  `;

const List = styled.ul`
    list-style-position: inside;
  `;

const ListItem = styled.li`
    text-align: left;

  `;

const DescriptorContainer = styled.div`
  width: 100%;
  height: 100wh;
`;

const DescriptorChildren = styled.div`
  margin-top: 6rem
`;

// TODO propose better name
const Descriptor = (props) => {

  const {header, content} = props;
  const renderText = () => {
      if(Array.isArray(content)) {
          return content.map(elem => <Text>{elem}</Text>)
      } else {
          return <Text>{content}</Text>;
      }
  }
  return (

          <DescriptorContainer>
            <Header>{header}</Header>
            {renderText()}
            {/*<Text>{content}</Text>*/}
            <DescriptorChildren>
              {props.children}
            </DescriptorChildren>
          </DescriptorContainer>

  );

}

export default Descriptor;